.border-button-effect {
  border-left: 2px solid #dfdfdf;
  border-top: 2px solid #dfdfdf;
  border-right: 2px solid #4b4b4b;
  border-bottom: 2px solid #4b4b4b;
}

.border-button-non-effect {
  border-left: 2px solid #4b4b4b;
  border-top: 2px solid #4b4b4b;
  border-right: 2px solid #dfdfdf;
  border-bottom: 2px solid #dfdfdf;
}
.border-button-effect:active {
  border-top: 2px solid #4b4b4b;
  border-left: 2px solid #4b4b4b;
  border-right: 2px solid #dfdfdf;
  border-bottom: 2px solid #dfdfdf;
}

.border-button-effect-dark {
  border-left: 2px solid #505050;
  border-top: 2px solid #505050;
  border-right: 2px solid #303030;
  border-bottom: 2px solid #303030;
}

.border-button-effect-dark:active {
  border-left: 2px solid #303030;
  border-top: 2px solid #303030;
  border-right: 2px solid #505050;
  border-bottom: 2px solid #505050;
}

.border-button-non-effect-dark {
  border-left: 2px solid #303030;
  border-top: 2px solid #303030;
  border-right: 2px solid #505050;
  border-bottom: 2px solid #505050;
}

.list-arrow {
  list-style: "➣";
}

.child-dir {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.child-dir-item {
  font-weight: 700;
  margin-right: 8px;
  color: lightgreen;
}

.root,
#root,
#docs-root {
  --window-transform-x: 0px;
  --window-transform-y: 0px;
}
