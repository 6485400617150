.desktop-menu {
  width: 160px;
  border-width: 1px;
  text-align: left;
  box-shadow: inset 1px 1px #dfdfdf, 1px 0 #000, 0 1px #000, 1px 1px #000;
  border-left: 2px solid #dfdfdf;
  border-top: 2px solid #dfdfdf;
  border-right: 2px solid #4b4b4b;
  border-bottom: 2px solid #4b4b4b;
  color: black;
  background-color: silver;
  padding-top: 4px;
  padding-bottom: 4px;
  z-index: 100;
  position: absolute;
  font-size: 14px;
  line-height: 20px;
  user-select: none;
}

.desktop-menu-dark {
  box-shadow: inset 1px 1px #505050, 1px 0 #000, 0 1px #000, 1px 1px #000;
  border-left: 2px solid #505050;
  border-top: 2px solid #505050;
  border-right: 2px solid #303030;
  border-bottom: 2px solid #303030;
  color: white;
  background-color: #282828;
}

.desktop-menu-active {
  display: block;
}

.desktop-menu-inactive {
  display: none;
}

.desktop-menu-item {
  width: 100%;
  display: block;
  padding-top: 4px;
  padding-bottom: 4px;
  margin-bottom: 6px;
  cursor: pointer;
}

.desktop-menu-item:hover {
  background-color: navy;
  color: white;
}

.desktop-menu-item-dark:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.desktop-menu-item-inactive {
  color: rgb(156, 163, 175);
  cursor: default;
}
