.default-main {
  background-color: white;
  color: #000;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  user-select: none;
}

.default-main-dark {
  background-color: #282828;
  color: #fff;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  user-select: none;
}

.heading-music {
  display: flex;
  height: 5%;
  font-size: 24px;
  font-weight: 700;
}

.music-button-wrapper {
  display: flex;
  margin-top: 10px;
  width: 100%;
  height: 12%;
  align-items: center;
  justify-content: center;
}

.music-button {
  height: 100%;
  width: 10%;
  margin: 5px;
  font-size: 18px;
  background-size: 25px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: silver;
  background-repeat: no-repeat;
  background-position: center;
  box-shadow: inset 1px 1px #dfdfdf, 1px 0 #000, 0 1px #000, 1px 1px #000;
  color: #000;
  cursor: pointer;
}

.music-button-dark {
  background-color: #383838;
  box-shadow: inset 1px 1px #505050, 1px 0 #000, 0 1px #000, 1px 1px #000;
}
