.projects-main {
  width: 100%;
  height: 95%;
  display: flex;
  position: absolute;
  justify-content: center;
}

.project-content-i {
  display: flex;
  width: 100%;
  margin-left: 4px;
  margin-right: 4px;
  margin-top: 6px;
  margin-bottom: 20px;
  padding: 1px;
  background-color: #c0c0c0;
  border-left: 2px solid #ededed;
  border-top: 2px solid #ededed;
  border-right: 2px solid #404040;
  border-bottom: 2px solid #404040;
}

.project-content-i-dark {
  display: flex;
  width: 100%;
  margin-left: 4px;
  margin-right: 4px;
  margin-top: 6px;
  margin-bottom: 20px;
  padding: 1px;
  background-color: #000;
  border-left: 2px solid #505050;
  border-top: 2px solid #505050;
  border-right: 2px solid #303030;
  border-bottom: 2px solid #303030;
}

.project-content-ii {
  margin: 2px;
  padding: 5px;
  width: 100%;
  overflow-y: scroll;
  color: #000;
  background-color: #ffffff;
  border-top: 2px solid #404040;
  border-left: 2px solid #404040;
  border-right: 2px solid #ededed;
  border-bottom: 2px solid #ededed;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.project-content-ii-dark {
  margin: 2px;
  padding: 5px;
  width: 100%;
  overflow-y: scroll;
  color: white;
  background-color: #282828;
  border-top: 2px solid #303030;
  border-left: 2px solid #303030;
  border-right: 2px solid #505050;
  border-bottom: 2px solid #505050;
}
.project-content-ii-dark::-webkit-scrollbar,
.project-content-ii::-webkit-scrollbar {
  display: none;
}

.project-container {
  position: relative;
  width: 100%;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 8px;
  margin-bottom: 8px;
  border: 1px solid;
  border-radius: 4px;
  color: #000;
  border-left: 2px solid #909090;
  border-top: 2px solid #909090;
  border-right: 2px solid #dfdfdf;
  border-bottom: 2px solid #dfdfdf;
  cursor: pointer;
}

.project-container:before {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  content: "";
  position: absolute;
  border-left: 1px solid #dfdfdf;
  border-top: 1px solid #dfdfdf;
  border-right: 1px solid #909090;
  border-bottom: 1px solid #909090;
}

.project-container:hover {
  --tw-bg-opacity: 0.1;
  background-color: rgba(0, 0, 128, var(--tw-bg-opacity));
}

.project-container-dark {
  position: relative;
  width: 100%;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 8px;
  margin-bottom: 8px;
  border: 1px solid;
  border-radius: 4px;
  color: #fff;
  border-left: 2px solid #303030;
  border-top: 2px solid #303030;
  border-right: 2px solid #505050;
  border-bottom: 2px solid #505050;
  cursor: pointer;
}

.project-container-dark:before {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  content: "";
  position: absolute;
  border-left: 1px solid #505050;
  border-top: 1px solid #505050;
  border-right: 1px solid #303030;
  border-bottom: 1px solid #303030;
}

.project-container-dark:hover {
  --tw-bg-opacity: 0.1;
  background-color: rgba(255, 255, 128, var(--tw-bg-opacity));
}
