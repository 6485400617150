.window-app {
  position: relative;
  margin: 2px;
  --tw-bg-opacity: 0;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  padding: 4px;
  border-color: transparent;
  outline: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  font-size: 12px;
  line-height: 16px;
  color: white;
  font-weight: 500;
  outline: 2px solid transparent;
  outline-offset: 2px;
  user-select: none;
  width: 80px;
  height: 80px;
  cursor: pointer;
  z-index: 10;
}

.window-app:hover {
  --tw-bg-opacity: 0.25;
}

.window-app:focus {
  background: rgba(3, 0, 127, 255);
  --tw-bg-opacity: 0.5;
  border-color: rgba(180, 83, 9, var(--tw-border-opacity));
}

.img_icon {
  width: 40px;
  margin-bottom: 8px;
}

.img-title {
  margin-top: 5px;
  color: white;
}
