.default-main {
  background-color: white;
  color: black;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
}

.default-main-dark {
  background-color: #282828;
  color: white;
}

.letter {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: 15%;
  margin-right: 15%;
}

.textarea-wrapper {
  background-color: white;
  color: black;
  outline: 1px solid transparent;
}

.textarea-wrapper-dark {
  background-color: #282828;
  color: white;
}

.input-button-wrapper {
  width: 40%;
  float: right;
  background-color: white;
  outline: 1px solid transparent;
}

.input-button-wrapper-dark {
  background-color: #282828;
}

.sm_textarea {
  font-size: 16px;
  line-height: 1.5rem;
  border: 0;
  outline: none;
  font-family: inherit;
  appearance: none;
  width: 100%;
  height: 8rem;
  resize: none;
}

.sm_input {
  width: 100%;
  margin-bottom: 5px;
  line-height: 1.5rem;
  border: 0;
  outline: none;
  font-family: inherit;
  appearance: none;
}

.sm_textarea,
.sm_input {
  color: #4e5e72;
  background-color: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='24'><rect fill='rgb(169,169,169)' x='0' y='23' width='10' height='1'/></svg>");
}

.sm_textarea-dark,
.sm_input-dark {
  color: white;
}

sm_input:focus,
sm_textarea:focus {
  outline: none;
}

.sm_button {
  cursor: pointer;
  float: right;
  width: 50%;
  display: flex;
  line-height: 1.5rem;
  outline: none;
  justify-content: center;
  align-items: center;
  font-family: inherit;
  appearance: none;
  padding: 0.5rem 1rem;
  background-color: silver;
  color: #494949;
  font-size: 16px;
}

.sm_button-dark {
  background-color: #383838;
  color: white;
}

.result-message {
  left: 0;
  right: 0;
  margin: auto;
}

.error-message {
  left: 0;
  right: 0;
  padding: 14px;
  text-align: center;
  color: darkred;
  border-radius: 5px;
  background-color: rgba(255, 0, 0, 0.2);
  border: 1px solid rgba(255, 0, 0, 0.4);
  margin: auto;
}
