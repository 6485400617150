.window-main {
  --tw-bg-opacity: 0.4;
  border: rgba(0, 0, 0, var(--tw-bg-opacity));
  border-top-width: 0;
  display: flex;
  flex-direction: column;
  min-width: 25%;
  min-height: 25%;
  position: absolute;
  overflow: hidden;
  border-left: 2px solid #ededed;
  border-top: 2px solid #ededed;
  border-right: 2px solid #404040;
  border-bottom: 2px solid #404040;
  box-shadow: 1px 4px 12px 4px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 1px 4px 12px 4px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 1px 4px 12px 4px rgba(0, 0, 0, 0.2);
}

.window-main-dark {
  color: #ffffff;
  background-color: #282828;
  border-left: 2px solid #505050;
  border-top: 2px solid #505050;
  border-right: 2px solid #303030;
  border-bottom: 2px solid #303030;
}

.window-main-focused {
  z-index: 50;
}

.window-main-not-focused {
  z-index: 20;
  filter: brightness(90%);
}

.window-edit-main {
  position: absolute;
  user-select: none;
  right: 0;
  top: 0;
  margin-top: 2px;
  margin-right: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.window-top-bar {
  position: relative;
  background-color: #c0c0c0;
  border-top-width: 2px;
  --tw-border-opacity: 0.05;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  justify-content: center;
  align-items: center;
  color: white;
  width: 100%;
  height: 32px;
  user-select: none;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}

.window-top-bar-dark {
  color: #ffffff;
  background-color: #282828;
}

.window-top-bar-content {
  display: flex;
  background-color: navy;
  justify-content: left;
  font-size: 14px;
  padding-left: 10px;
  line-height: 26px;
  margin: 3px;
  font-weight: 350;
}

.window-top-bar-content-dark {
  background-color: #000;
}

.top-bar-icon {
  height: 18px;
  width: 18px;
  margin-top: 4px;
  margin-bottom: 4px;
  margin-right: 6px;
}

.nav-bar-btn {
  margin: 3px;
  cursor: pointer;
  background-color: silver;
  display: flex;
  justify-content: center;
  margin-top: 5px;
  height: 18px;
  width: 18px;
  background-repeat: no-repeat;
  background-position: center;
  align-items: center;
  border-left: 2px solid #ededed;
  border-top: 2px solid #ededed;
  border-right: 2px solid #404040;
  border-bottom: 2px solid #404040;
}

.nav-bar-btn:hover {
  --tw-bg-opacity: 0.1;
}

.nav-bar-btn:focus {
  outline: none;
}

.nav-bar-btn:active {
  border-top: 2px solid #404040;
  border-left: 2px solid #404040;
  border-right: 2px solid #ededed;
  border-bottom: 2px solid #ededed;
}

.nav-bar-btn-dark {
  background-color: #383838;
  border-left: 2px solid #505050;
  border-top: 2px solid #505050;
  border-right: 2px solid #303030;
  border-bottom: 2px solid #303030;
}

.nav-bar-btn-dark:active {
  border-top: 2px solid #303030;
  border-left: 2px solid #303030;
  border-right: 2px solid #505050;
  border-bottom: 2px solid #505050;
}

.window-main-screen {
  width: 100%;
  display: flex;
  flex-grow: 1;
  z-index: 25;
  max-height: 100%;
  overflow-y: auto;
  background-color: #c0c0c0;
}

.window-main-screen-dark {
  background-color: #282828;
}

.window-main-screen-content-i {
  display: flex;
  width: 100%;
  margin-left: 3px;
  margin-right: 4px;
  margin-top: 6px;
  margin-bottom: 20px;
  padding: 1px;
  background-color: #c0c0c0;
  border-left: 2px solid #ededed;
  border-top: 2px solid #ededed;
  border-right: 2px solid #404040;
  border-bottom: 2px solid #404040;
}

.window-main-screen-content-i-dark {
  background-color: #000;
  border-left: 2px solid #303030;
  border-top: 2px solid #303030;
  border-right: 2px solid #505050;
  border-bottom: 2px solid #505050;
}

.window-main-screen-content-ii {
  margin: 2px;
  width: 100%;
  background-color: #c0c0c0;
  border-top: 2px solid #404040;
  border-left: 2px solid #404040;
  border-right: 2px solid #ededed;
  border-bottom: 2px solid #ededed;
}

.window-main-screen-content-ii-dark {
  background-color: #282828;
  border-top: 2px solid #505050;
  border-left: 2px solid #505050;
  border-right: 2px solid #303030;
  border-bottom: 2px solid #303030;
}

.btn-inline {
  display: inline;
}

.closed-window {
  animation: closeWindow 200ms 1 forwards;
}

@keyframes closeWindow {
  0% {
    opacity: 1;
    transform: translate(var(--window-transform-x), var(--window-transform-y))
      scale(1);
    visibility: visible;
  }

  100% {
    opacity: 0;
    transform: translate(var(--window-transform-x), var(--window-transform-y))
      scale(0.85);
    visibility: hidden;
  }
}

.minimized-window {
  opacity: 0;
  visibility: hidden;
  transition-duration: 200;
}

.maximized-window {
  transition-duration: 200ms;
  border-radius: 0px;
}
