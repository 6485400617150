.start-bar {
  box-shadow: inset 1px 0 #fff;
  width: 100%;
  background-color: silver;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: 1px solid #f4f4f4;
  border-bottom: 1px solid #4e4e4e;
  position: fixed;
  height: 38px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  user-select: none;
  z-index: 100;
}

.start-bar-dark {
  box-shadow: inset 1px 0 #000;
  background-color: #282828;
  border-top: 1px solid #303030;
  border-bottom: 1px solid #505050;
}

.start-button {
  margin-left: 6px;
  margin-right: 8px;
  padding: 2px 6px 3px;
  background-color: silver;
  box-shadow: inset 1px 1px #dfdfdf, 1px 0 #000, 0 1px #000, 1px 1px #000;
  min-width: 45px;
  background-repeat: no-repeat;
  background-position: center;
  width: 65px;
  height: 22px;
  color: #000;
  cursor: pointer;
}

.start-button-dark {
  box-shadow: inset 1px 1px #505050, 1px 0 #000, 0 1px #000, 1px 1px #000;
  background-color: #383838;
  color: #fff;
}

.button-wrapper {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: auto;
  height: 22px;
  margin: 0;
  margin-right: 4px;
  font-size: small;
  font-family: Arial;
  font-weight: 400;
  color: #000;
  text-align: center;
  padding: 2px 8px 3px;
  border-top: 1px solid #4b4b4b;
  border-left: 1px solid #4b4b4b;
  border-right: 1px solid #dfdfdf;
  border-bottom: 1px solid #dfdfdf;
}

.button-wrapper-dark {
  border-top: 1px solid #303030;
  border-left: 1px solid #303030;
  border-right: 1px solid #505050;
  border-bottom: 1px solid #505050;
}

.icon-div {
  display: inline-block;
  height: 100%;
  width: 20px;
  margin-right: 8px;
  background-repeat: no-repeat;
  background-position: center;
}

.time-div {
  margin-left: 5px;
  justify-content: center;
  text-align: center;
}

.time-div-dark {
  color: #fff;
}

.start-button:active {
  -webkit-transform: translate3d(0, 1px, 0);
  transform: translate3d(0, 1px, 0);
}

.start-button:focus {
  outline: #000 dotted 1px;
}

.recent-app-wrapper {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 25px;
  height: 22px;
  margin: 0;
  margin-right: 6px;
  font-size: small;
  font-family: Arial;
  font-weight: 400;
  color: #000;
  text-align: center;
  padding: 2px 0px 3px 8px;
  background-color: silver;
  cursor: pointer;
}

.recent-app-wrapper-focused {
  background-color: silver;
  width: auto;
  padding: 2px 50px 3px 8px;
}

.recent-app-wrapper-dark {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 25px;
  height: 22px;
  margin: 0;
  margin-right: 6px;
  font-size: small;
  font-family: Arial;
  font-weight: 400;
  text-align: center;
  padding: 2px 0px 3px 8px;
  cursor: pointer;
  color: white;
  background-color: #383838;
}

.recent-app-wrapper-focused-dark {
  background-color: #383838;
  width: auto;
  padding: 2px 50px 3px 8px;
}
