.overlay-splash-screen {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  background-position: 50% 50%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  transition: opacity 800ms ease-out 0s;
}
.loading-bar {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  height: 38px;
  width: 100%;
  background-size: 32px 35px;
  background-position: 0px 0px;
  background-repeat: repeat-x;
  animation: slide 6s linear infinite;
}

@keyframes slide {
  0% {
    transform: translate3d(-1692px, 0, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}

.body {
  font-family: Arial, sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: #333;
}
