.explorer-main {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #c0c0c0;
  color: #000;
}

.explorer-main-dark {
  background-color: #282828;
  color: #fff;
}

.screen-explorer {
  flex-grow: 1;
}

.nav-bar-explorer {
  width: 100%;
  margin-top: 4px;
  padding-bottom: 6px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: black;
  font-size: small;
  border-bottom-width: 1px;
  border-color: rgba(17, 24, 39);
}

.nav-bar-btn-explorer {
  width: 22px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: center;
}

.refresh-btn-explorer {
  margin-top: 4px;
  margin-left: 8px;
  margin-right: 4px;
}

.home-btn-explorer {
  margin-top: 2px;
  margin-right: 8px;
  margin-left: 4px;
}

.home-btn:hover {
  --tw-border-opacity: 0.1;
}

.nav-bar-url-bar-explorer {
  outline: 2px solid transparent;
  outline-offset: 2px;
  padding-left: 12px;
  width: 92%;
  padding-top: 2px;
  padding-bottom: 2px;
  margin-right: 12px;
  color: black;
  background-color: white;
}

.nav-bar-url-bar-explorer-dark {
  background-color: #505050;
  color: white;
}
