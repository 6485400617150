.setting-main {
  width: 100%;
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  z-index: 20;
  position: relative;
  overflow-y: auto;
  user-select: none;
  background-color: #c0c0c0;
}

.setting-main-dark {
  background-color: #282828;
}

.setting-main::-webkit-scrollbar {
  display: none;
}
.monitor-img {
  position: absolute;
  width: 275px;
  height: 275px;
  max-width: 768px;
  margin: auto;
  margin-top: 16px;
  margin-bottom: 16px;
}

.monitor-img-content {
  position: absolute;
  width: 194px;
  height: 144px;
  margin-top: 50px;
  margin-left: 40px;
}

.wallpaper-box {
  margin-top: 300px;
  margin-bottom: 20px;
  position: relative;
  padding: 25px;
  border-left: 1px solid #909090;
  border-top: 1px solid #909090;
  border-right: 1px solid #dfdfdf;
  border-bottom: 1px solid #dfdfdf;
}

.wallpaper-box:before {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  content: "";
  position: absolute;
  border-left: 1px solid #dfdfdf;
  border-top: 1px solid #dfdfdf;
  border-right: 1px solid #909090;
  border-bottom: 1px solid #909090;
  z-index: -1;
}

.wallpaper-box-dark {
  border-left: 1px solid #505050;
  border-top: 1px solid #505050;
  border-right: 1px solid #303030;
  border-bottom: 1px solid #303030;
}

.wallpaper-box-dark::before {
  border-left: 1px solid #303030;
  border-top: 1px solid #303030;
  border-right: 1px solid #505050;
  border-bottom: 1px solid #505050;
}

.wallpaper-text {
  margin-top: -35px;
  width: 75px;
  background-color: #c0c0c0;
}

.wallpaper-text-dark {
  background-color: #282828;
}

.wallpaper-box-with-heading {
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  background-color: white;
  border-left: 2px solid #404040;
  border-top: 2px solid #404040;
  border-right: 2px solid #ededed;
  border-bottom: 2px solid #ededed;
}

.wallpaper-box-with-heading-dark {
  background-color: #404040;
  border-left: 2px solid #505050;
  border-top: 2px solid #505050;
  border-right: 2px solid #303030;
  border-bottom: 2px solid #303030;
}

.wallpaper-list {
  width: 220px;
  position: relative;
  list-style-type: none;
  padding: 3px;
  padding-left: 14px;
}
.wallpaper-list:hover {
  background-color: rgba(3, 0, 127, 255);
  color: white;
}

.wallpaper-list-dark:hover {
  background-color: #000000;
  color: white;
}

.switch-btn {
  top: 0;
  right: 0;
  width: 75px;
  height: 50px;
  background-color: transparent;
  position: absolute;
  display: flex;
  margin-top: 15px;
  margin-right: 5px;
}
