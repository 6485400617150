.terminal-main {
  height: 100%;
  width: 100%;
  background-color: #fff;
  color: #000;
  font-size: small;
  font-weight: bold;
  overflow-y: scroll;
}

.terminal-main-dark {
  background-color: black;
  color: #fff;
}

.terminal-cmd {
  background: transparent;
  position: relative;
  flex: 1;
  overflow: hidden;
}

.terminal-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0;
  outline: none;
  pointer-events: none;
  background: transparent;
}

.terminal-main::-webkit-scrollbar {
  display: none;
}
