.desktop-main {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
  background: transparent;
  position: relative;
  overflow: hidden;
  overscroll-behavior: none;
}

.window-area {
  position: absolute;
  height: 97.8%;
  width: 100%;
  background: transparent;
}

.ads-area-desktop {
  right: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-right: 10px;
  position: absolute;
  width: 25%;
  height: 75%;
  background-color: transparent;
  z-index: 10;
  user-select: none;
}
