.marker-wrapper {
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  margin-bottom: 70px;
  position: absolute;
  height: 10%;
  width: 16%;
  padding: 1%;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 200;
  user-select: none;
}

.created-div {
  font-size: 24px;
  color: rgba(255, 255, 255, 0.5);
}

.creator-div {
  margin-top: 5px;
  font-size: 18px;
  color: rgba(255, 255, 255, 0.5);
}
