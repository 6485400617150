.menu-item {
  position: relative;
  display: flex;
  justify-content: left;
  align-items: center;
  text-align: center;
  padding: 12px 0px 12px 8px;
  color: black;
  text-decoration: none;
  width: 100%;
  background-color: silver;
  border: none;
  cursor: pointer;
  float: left;
  font-size: 16px;
}

.menu-item-dark {
  background-color: #282828;
  color: #fff;
}

.menu-item-shut {
  position: relative;
  display: flex;
  justify-content: left;
  align-items: center;
  text-align: center;
  padding: 12px 0px 12px 8px;
  color: black;
  text-decoration: none;
  width: 100%;
  background-color: silver;
  border-top-width: 2px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
  border-top-color: rgba(124, 124, 124, 10);
  cursor: pointer;
  float: left;
  font-size: 16px;
}

.menu-item-shut-dark {
  background-color: #282828;
  color: #fff;
}

.menu-item:focus {
  outline: none;
}

.menu-item .icon-button:hover {
  filter: none;
}

.menu-item-shut:hover,
.menu-item:hover {
  background-color: rgba(3, 0, 127, 255);
  color: white;
}

.menu-item-dark:hover,
.menu-item-shut-dark:hover {
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
}

.dropdown {
  display: inline-block;
  position: absolute;
  z-index: 125;
  background-color: silver;
  left: 4px;
  bottom: 40px;
  width: 300px;
  height: 450px;
  border-left: 3px solid #dfdfdf;
  border-top: 3px solid #dfdfdf;
  border-right: 3px solid #4b4b4b;
  border-bottom: 3px solid #4b4b4b;
  transition: height var(--speed) ease;
  user-select: none;
}

.dropdown-dark {
  background-color: #282828;
  border-left: 3px solid #505050;
  border-top: 3px solid #505050;
  border-right: 3px solid #303030;
  border-bottom: 3px solid #303030;
}

.dropdown-sidebar {
  display: inline-block;
  position: absolute;
  width: 45px;
  height: 100%;
}

.menu {
  display: inline-block;
  position: relative;
  margin-left: 45px;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-right: 0px;
}

/* CSSTransition classes  */
.menu-primary-enter {
  position: absolute;
  transform: translateX(-110%);
}
.menu-primary-enter-active {
  transform: translateX(0%);
  transition: all var(--speed) ease;
}
.menu-primary-exit {
  position: absolute;
}
.menu-primary-exit-active {
  transform: translateX(-110%);
  transition: all var(--speed) ease;
}

.menu-icon {
  display: inline-block;
  height: 30px;
  width: 30px;
  margin-left: 10px;
  background-size: 20px 20px;
  background-position: center;
  align-items: center;
  justify-content: center;
  margin-right: 25px;
}

.menu-icon-shut {
  display: inline-block;
  height: 40px;
  width: 40px;
  margin-left: 4px;
  background-size: 20px 20px;
  background-position: center;
  align-items: center;
  justify-content: center;
  margin-right: 21px;
}

.menu-text {
  display: inline-block;
  text-align: center;
  line-height: 40px;
}

.menu-text:first-letter {
  text-decoration: underline;
}

.windows-text {
  background-color: rgba(124, 124, 124, 255);
  height: 100%;
  width: 100%;
  z-index: 11;
}

.win-text {
  -moz-transform-origin: 0 50%;
  -moz-transform: rotate(-90deg) translate(-50%, 50%);
  -webkit-transform-origin: 0 50%;
  -webkit-transform: rotate(-90deg) translate(-50%, 50%);
  -o-transform-origin: 0 50%;
  -o-transform: rotate(-90deg) translate(-50%, 50%);
  -ms-transform-origin: 0 50%;
  -ms-transform: rotate(-90deg) translate(-50%, 50%);
  transform-origin: 0 50%;
  transform: rotate(-90deg) translate(-50%, 50%);
  position: absolute;
  top: 0;
  font-family: Arial, Helvetica, sans-serif;
  bottom: -400px;
  left: 12px;
  right: 0;
  height: 2em; /* line-height of .wrapper div:first-child span */
  margin: auto;
  font-weight: bold;
  font-size: 40px;
  color: #c3c3c3;
  line-height: 20px;
}

.numb-text {
  -moz-transform-origin: 0 50%;
  -moz-transform: rotate(-90deg) translate(-50%, 50%);
  -webkit-transform-origin: 0 50%;
  -webkit-transform: rotate(-90deg) translate(-50%, 50%);
  -o-transform-origin: 0 50%;
  -o-transform: rotate(-90deg) translate(-50%, 50%);
  -ms-transform-origin: 0 50%;
  -ms-transform: rotate(-90deg) translate(-50%, 50%);
  transform-origin: 0 50%;
  transform: rotate(-90deg) translate(-50%, 50%);
  position: absolute;
  top: 0;
  bottom: -40px;
  left: 15px;
  right: 0;
  font-family: Arial, Helvetica, sans-serif;
  height: 2em; /* line-height of .wrapper div:first-child span */
  margin: auto;
  font-weight: 300;
  font-size: 34px;
  line-height: 20px;
  color: rgb(230, 230, 230);
}
