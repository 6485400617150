.about-main {
  height: 100%;
  width: 100%;
  display: flex;
  user-select: none;
  flex-direction: column;
  background-color: #c0c0c0;
}

.nav-bar-about {
  position: absolute;
  display: flex;
  width: 100%;
  height: 20px;
  font-size: 13px;
  font-family: Arial, Helvetica, sans-serif;
  left: 5px;
}

.nav-bar-about-item {
  padding: 2px;
  margin-right: 4px;
  cursor: pointer;
}

.nav-bar-about-item:first-letter {
  text-decoration: underline;
}
.nav-bar-about-item:hover {
  background-color: navy;
  color: white;
}

.nav-bar-about-item-dark:hover {
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
}

.about-content {
  width: 100%;
  height: 92%;
  position: absolute;
  display: flex;
  margin-top: 20px;
}

.about-content-i {
  display: flex;
  width: 100%;
  margin-left: 4px;
  margin-right: 4px;
  margin-top: 6px;
  margin-bottom: 20px;
  padding: 1px;
  background-color: #c0c0c0;
  border-left: 2px solid #ededed;
  border-top: 2px solid #ededed;
  border-right: 2px solid #404040;
  border-bottom: 2px solid #404040;
}

.about-content-i-dark {
  background-color: #000000;
  border-left: 2px solid #505050;
  border-top: 2px solid #505050;
  border-right: 2px solid #303030;
  border-bottom: 2px solid #303030;
}

.about-content-ii {
  margin: 2px;
  padding: 5px;
  width: 100%;
  overflow-y: scroll;
  background-color: #ffffff;
  border-top: 2px solid #404040;
  border-left: 2px solid #404040;
  border-right: 2px solid #ededed;
  border-bottom: 2px solid #ededed;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.about-content-ii-dark {
  background-color: #282828;
  border-top: 2px solid #303030;
  border-left: 2px solid #303030;
  border-right: 2px solid #505050;
  border-bottom: 2px solid #505050;
}

.about-content-ii::-webkit-scrollbar {
  display: none;
}

.about-me-logo {
  width: 100px;
  margin: auto;
  margin-top: 16px;
  margin-bottom: 16px;
}

.img-tag {
  margin: 4px;
}

.typewriter-wrapper {
  display: flex;
  width: 75%;
  position: absolute;
  flex-direction: column;
  flex-flow: row;
  flex-wrap: wrap;
  align-items: left;
  text-align: justify;
  margin-left: 10%;
  margin-right: 10px;
  margin-top: 20px;
  font-size: 14px;
}

.social-footer {
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
}

.social-icon-div {
  height: 25px;
  width: 25px;
  margin-right: 10px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 22px 22px;
  cursor: pointer;
}

.college-heading {
  font-size: 18px;
  line-height: 28px;
  text-align: left;
  font-weight: 700;
  cursor: pointer;
}

.college-date {
  font-size: 12px;
  line-height: 16px;
  margin-top: 2px;
}

.college-course {
  font-size: 14px;
  line-height: 20px;
}

.skill-title {
  width: 50%;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  font-weight: 700;
}

.skill-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  text-align: center;
  font-weight: 700;
}

.skill-tag-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  margin-top: 8px;
}

.domain-tag {
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 2px;
  padding-bottom: 2px;
  text-decoration: none;
  width: max-content;
  border: 1px solid;
  margin: 4px;
  border-radius: 9999px;
}
