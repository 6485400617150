.bg-window-img {
  height: 100vh;
  width: 100vw;
  position: absolute;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: 0px 0px;
  z-index: -10;
  top: 0;
  right: 0;
  overflow: hidden;
}
